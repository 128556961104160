
import { useTable } from 'react-table'

export function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })

    return (
      <div className="w-full mt-2 flex flex-col shadow-2xl">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-primary sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-primary bg-white table-auto">
                <thead className="bg-gray-50">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                
                <tbody className="bg-white divide-y divide-primary" {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row)
                      return (
                        <tr className="hover:bg-primary-light" {...row.getRowProps()}>
                          {
                            row.cells.map(cell => {
                              return <td className="px-6 py-4" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })
                          }
                        </tr>
                      )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }