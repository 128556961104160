import axios from "axios";
import { config } from "../config";
import { makeObservable, action, observable } from 'mobx';

// Create `axios` instance passing the newly created `cache.adapter`
export class AuthStore {
  api: any;
  token: string;
  key: string;

  school: any;
  schoolType: any;
  subject: any;
  class: any;
  lesson: any;
  lessonGroup: any;
  educationLevel: any;
  country: any;
  educationLevelHistory = [];

  profile: any;
  loggingIn: boolean;
  loginError: any;

  constructor() {
    makeObservable(this, {
      profile: observable,
      token: observable,
    })

    this.loggingIn = false;

    try {
      this.restoreLocalInfo();
    } catch {
      this.profile = null;
      this.token = null;
      this.school = null;
    }

    this.api = axios.create({});

    // Add a response interceptor
    this.api.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
    
    // Add a request interceptor
    this.api.interceptors.request.use(
      function (config) {
        
        let token = localStorage.getItem('token');
        if (token !== undefined && token !== null && token.length > 0) {
          config.headers["Authorization"] = `JWT ${token}`;
        }
        
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );    
  }

  setKey(key: string) {
    this.key = key;

    localStorage.setItem('key', JSON.stringify(key));
  }

  resetLocalInfo() {
    this.token = null;
    this.profile = null;
    this.school = null;

    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    localStorage.removeItem('school');

    this.clearLocalCountry();
    this.clearLocalEducationLevelHistory();
    this.clearLocalLessonGroup();
    this.clearLocalLesson();
  }

  saveLocalInfo(data) {
    this.token = data.token;
    this.profile = data.user;
    this.school = data.user.school;

    localStorage.setItem('token', this.token);
    localStorage.setItem('profile', JSON.stringify(this.profile));
    localStorage.setItem('school', JSON.stringify(this.school));
  }

  getProfile() {
    if (this.profile != null) {
      return this.profile;
    }

    let profile = localStorage.getItem('profile');
    if (profile == null)
      return false;

    let pProfile = JSON.parse(profile);

    if (pProfile.type == undefined)
      return null;

    this.profile = pProfile;

    return pProfile;
  }

  isUser() {
    let profile = this.getProfile();
    if (profile == null)
      return false;

    if (profile.type == 2)
      return true;

    return false;
  }

  isSchool() {
    let profile = this.getProfile();
    if (profile == null)
      return false;

    if (profile.type == 3)
      return true;

    return false;
  }

  isPartner() {
    let profile = this.getProfile();
    if (profile == null)
      return false;

    if (profile.type == 5)
      return true;

    return false;
  }

  isAdmin() {
    let profile = this.getProfile();
    if (profile == null)
      return false;

    if (profile.type == 1)
      return true;

    return false;
  }

  restoreLocalInfo() {
    let token = localStorage.getItem('token');
    let profile = localStorage.getItem('profile');
    let school = localStorage.getItem('school');
    let key = localStorage.getItem('key');
  
    if (token !== undefined)
      this.token = token;

    if (profile !== undefined)
      this.profile = JSON.parse(profile);

    if (school !== undefined)
      this.school = JSON.parse(school);

    if (key !== undefined)
      this.key = JSON.parse(key);
  }

  saveLocalCountry(country) {
    this.country = country;
    localStorage.setItem('country', JSON.stringify(country));
  }

  clearLocalCountry() {
    this.lesson = null;
    localStorage.removeItem('country');
  }

  saveLocalSubject(subject) {
    this.subject = subject;
    localStorage.setItem('subject', JSON.stringify(subject));
  }

  saveLocalSchoolType(schoolType) {
    this.schoolType = schoolType;
    localStorage.setItem('schoolType', JSON.stringify(schoolType));
  }

  saveLocalClass(classE) {
    this.class = classE;
    localStorage.setItem('class', JSON.stringify(classE));
  }

  saveLocalEducationLevel(educationLevel) {
    this.educationLevel = educationLevel;
    localStorage.setItem('education-level', JSON.stringify(educationLevel));
  }

  clearLocalEducationLevel() {
    this.educationLevel = null;
    localStorage.removeItem('education-level');
  }

  clearLocalEducationLevelHistory() {
    this.educationLevelHistory = [];
    localStorage.setItem('education-level-history', JSON.stringify([]));

    this.educationLevel = null;
    localStorage.removeItem('education-level');
  }

  pushLocalEducationLevelHistory(educationLevel) {
    this.educationLevelHistory.push(educationLevel);
    localStorage.setItem('education-level-history', JSON.stringify(this.educationLevelHistory));
  }

  popLocalEducationLevelHistory() {
    if (this.educationLevelHistory.length == 0)
      return null;

    let item = this.educationLevelHistory.pop();
    localStorage.setItem('education-level-history', JSON.stringify(this.educationLevelHistory));

    return item;
  }

  saveLocalLesson(lesson) {
    this.lesson = lesson;
    localStorage.setItem('lesson', JSON.stringify(lesson));
  }

  clearLocalLesson() {
    this.lesson = null;
    localStorage.removeItem('lesson');
  }

  saveLocalLessonGroup(lessonGroup) {
    this.lessonGroup = lessonGroup;
    localStorage.setItem('lesson-group', JSON.stringify(lessonGroup));
  }

  clearLocalLessonGroup() {
    this.lessonGroup = null;
    localStorage.removeItem('lesson-group');
  }

  getLocalToken() {
    if (this.token !== undefined && this.token !== null)
      return this.token;

    let token = localStorage.getItem('token');
    if (token !== undefined && token !== null)
      return token;

    return "";
  }

  getLocalSchool() {
    if (this.school !== undefined && this.school !== null)
      return this.school;

    let school = localStorage.getItem('school');
    if (school !== undefined && school !== null)
      return JSON.parse(school);

    console.log("school empty, exiting...")
    //window.location.replace(window.location.origin + "/login/")
    return null;
  }

  getLocalSchoolType() {
    if (this.schoolType !== undefined && this.schoolType !== null)
      return this.schoolType;

    let schoolType = localStorage.getItem('schoolType');
    if (schoolType !== undefined && schoolType !== null)
      return JSON.parse(schoolType);

    console.log("school type empty, exiting...")
    //window.location.replace(window.location.origin + "/login/")
    return null;
  }

  getLocalSubject() {
    if (this.subject !== undefined && this.subject !== null)
      return this.subject;

    let subject = localStorage.getItem('subject');
    if (subject !== undefined && subject !== null)
      return JSON.parse(subject);

    console.log("local subject empty, exiting...")
    //window.location.replace(window.location.origin + "/login/")
    return null;
  }

  getLocalLesson() {
    if (this.lesson !== undefined && this.lesson !== null)
      return this.lesson;

    let lesson = localStorage.getItem('lesson');
    if (lesson !== undefined && lesson !== null)
      return JSON.parse(lesson);

    console.log("local lesson empty, exiting...")
    //window.location.replace(window.location.origin + "/login/")
    return null;
  }

  getLocalCountry() {
    if (this.country !== undefined && this.country !== null)
      return this.country;

    let el = localStorage.getItem('country');
    if (el !== undefined && el !== null)
      return JSON.parse(el);

    console.log("local country empty, exiting...")
    //window.location.replace(window.location.origin + "/login/")
    return null;
  }

  getLocalEducationLevel() {
    if (this.educationLevel !== undefined && this.educationLevel !== null)
      return this.educationLevel;

    let el = localStorage.getItem('education-level');
    if (el !== undefined && el !== null)
      return JSON.parse(el);

    console.log("local education level empty")
    return null;
  }

  getLocalLessonGroup() {
    if (this.lessonGroup !== undefined && this.lessonGroup !== null)
      return this.lessonGroup;

    let lessonGroup = localStorage.getItem('lesson-group');
    if (lessonGroup !== undefined && lessonGroup !== null)
      return JSON.parse(lessonGroup);

    console.log("local lesson empty")
    //window.location.replace(window.location.origin + "/login/")
    return null;
  }

  getLocalClass() {
    if (this.class !== undefined && this.class !== null)
      return this.class;

    let classE = localStorage.getItem('class');
    if (classE !== undefined && classE !== null)
      return JSON.parse(classE);

    console.log("local class level empty")
    //window.location.replace(window.location.origin + "/login/")
    return null;
  }

  login = async (email: string, password: string) => {
    this.loggingIn = true;

    try {
      const response = await this.api.post(`${config.getEndpointUri()}/auth/signin/`, {
        email,
        password,
        key: this.key
      });

      if (!response.data.success) {
        this.loginError = "Wrong auth info";
        return response.data;
      } else {
        this.saveLocalInfo(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      this.loggingIn = false;
    }
  };

  signup = async (email: string, password: string, code: string, activationCode: string,
    name: string, address: string, city: string, province: string, region: string, telephone_number: string, zip_code: string) => {

      try {
        const response = await this.api.post(`${config.getEndpointUri()}/auth/signup/`, {
          email: email,
          password: password,
          code: code,
          activation_code: activationCode,
          key: this.key,
          name: name,
          address: address,
          city: city,
          province: province,
          region: region,
          zip_code: zip_code,
          telephone_number: telephone_number
        });

      if (response.data.error != null) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      this.loginError = error.response;
    } finally {
      this.loggingIn = false;
    }
  };

  sendOTP = async (email: string) => {
    try {
      const response = await this.api.post(`${config.getEndpointUri()}/auth/send_otp/`, {
        email: email,
      });

      if (response.data.error != null) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  passwordReset = async (code: string, email: string) => {
    try {
      const response = await this.api.post(`${config.getEndpointUri()}/auth/reset_password/`, {
        code: code,
        email: email,
      });

      if (response.data.error != null) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  professorSignup = async (name: string, surname: string, email: string, password: string, code: string) => {

      try {
        const response = await this.api.post(`${config.getEndpointUri()}/auth/professor_signup/`, {
          name: name,
          surname: surname,
          email: email,
          password: password,
          code: code,
        });

      if (response.data.error != null) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      this.loginError = error.response;
    } finally {
      this.loggingIn = false;
    }
  };

  getCountries() {
    return this.api.get(`${config.getEndpointUri()}/registry/countries/`);
  }

  getCountry(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/countries/${id}/`);
  }

  getSelfCountry() {
    return this.api.get(`${config.getEndpointUri()}/registry/countries/self/`);
  }

  addCountry(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/countries/`, data);
  }

  editCountry(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/countries/${id}/`, data);
  }

  searchSchool(key) {
    return this.api.get(`${config.getEndpointUri()}/registry/schools/search/?key=${key}`);
  }

  getSchools(searchKey = null, countryId=null) {
    if (searchKey == null || searchKey.length == 0)
      return this.api.get(`${config.getEndpointUri()}/registry/schools/`);

    return this.api.get(`${config.getEndpointUri()}/registry/schools/?key=${searchKey}`);
  }

  getSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/schools/${id}/`);
  }

  addSchool(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/schools/`, data);
  }

  editSchool(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/schools/${id}/`, data);
  }

  editSchoolLessonGroups(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/schools/${id}/lesson_groups/`, data);
  }

  getAllSubjects() {
    return this.api.get(`${config.getEndpointUri()}/registry/subjects/`);
  }

  getAllClasses() {
    return this.api.get(`${config.getEndpointUri()}/registry/classes/`);
  }

  getSubjectsOfSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/school/${id}/subjects/`);
  }

  getClassesOfSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/${id}/classes/`);
  }

  getLessonGroupsOfSchool(schoolId, subjectId, classId) {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/?school=${schoolId}&subject=${subjectId}&class=${classId}`);
  }

  getLessonsOfGroup(id, type) {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/?type=${type}&group=${id}`);
  }

  getUsers(type, searchKey = null) {
    let t = `?type=${type}`;
    let sc = "";

    if (searchKey != null && searchKey.length > 0)
      sc = `&key=${searchKey}`

    return this.api.get(`${config.getEndpointUri()}/registry/users/${t}${sc}`);
  }

  getRegCode() {
    return this.api.get(`${config.getEndpointUri()}/registry/schools/regcode/`);
  }

  checkRegCode(code: string) {
    return this.api.get(`${config.getEndpointUri()}/registry/schools/regcode_check/?regcode=${code}`);
  }

  delRegCode() {
    return this.api.delete(`${config.getEndpointUri()}/registry/schools/regcode/`);
  }

  getUsersOfSchool(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/users/?school=${id}`);
  }

  getUser(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/users/${id}/`);
  }

  getSelf() {
    return this.api.get(`${config.getEndpointUri()}/registry/users/self/`);
  }
  
  addUser(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/users/`, data);
  }

  editUser(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/users/${id}/`, data);
  }

  deleteUser(id) {
    return this.api.delete(`${config.getEndpointUri()}/registry/users/${id}/`);
  }

  editPassword(id, password) {
    return this.api.post(`${config.getEndpointUri()}/registry/users/${id}/set_password/`, {password: password});
  }

  uploadMedia(data, onUploadProgressCallback) {
    return this.api.post(`${config.getEndpointUri()}/content/lessons/media/`, data, 
    {
      onUploadProgress: (progressEvent) => {
        onUploadProgressCallback((progressEvent.loaded / progressEvent.total) * 100);
      },
    });
  }

  getEducationLevels(countryId, parentId) {
    return this.api.get(`${config.getEndpointUri()}/registry/countries/${countryId}/education-levels/?parent=${parentId}`);
  }

  getEducationLevel(countryId, id) {
    return this.api.get(`${config.getEndpointUri()}/registry/countries/${countryId}/education-levels/${id}/`);
  }

  addEducationLevel(countryId, data) {
    return this.api.post(`${config.getEndpointUri()}/registry/countries/${countryId}/education-levels/`, data);
  }

  editEducationLevel(countryId, id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/countries/${countryId}/education-levels/${id}/`, data);
  }

  delEducationLevel(countryId, id) {
    return this.api.delete(`${config.getEndpointUri()}/registry/countries/${countryId}/education-levels/${id}/`);
  }

  addLessonGroupsToEducationLevel(countryId, id, data) {
    return this.api.post(`${config.getEndpointUri()}/registry/countries/${countryId}/education-levels/${id}/assign/`, data);
  }

  removeLessonGroupsFromEducationLevel(countryId, id, data) {
    return this.api.post(`${config.getEndpointUri()}/registry/countries/${countryId}/education-levels/${id}/remove/`, [data]);
  }

  getLessons(searchKey = null, country = null) {
    var url = "";

    if (searchKey == null || searchKey.length == 0) {
      url = `${config.getEndpointUri()}/content/lessons/`;
      if (country != null) {
        url += `?country=${country}`
      }
    
    } else {
      url = `${config.getEndpointUri()}/content/lessons/?key=${searchKey}`;
      if (country != null) {
        url += `?country=${country}`
      }
    }

    return this.api.get(url);
  }

  getDownloadableLessons(isMiri, isMiriPro) {
    let type = 1;

    if (isMiri) {
      type = 2;
    }
    if (isMiriPro) {
      type = 3;
    }
    return this.api.get(`${config.getEndpointUri()}/content/lessons/?cache=1&type=${type}`);
  }

  getLessonsFiltered(lessonGroup) {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/?group=${lessonGroup.id}`);
  }

  getLessonsPersonal() {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/?personal=1`);
  }

  addLesson(data) {
    return this.api.post(`${config.getEndpointUri()}/content/lessons/`, data);
  }

  editLesson(id, data) {
    return this.api.put(`${config.getEndpointUri()}/content/lessons/${id}/`, data);
  }

  deleteLesson(id) {
    return this.api.delete(`${config.getEndpointUri()}/content/lessons/${id}/`);
  }

  getLesson(id) {
    return this.api.get(`${config.getEndpointUri()}/content/lessons/${id}/`);
  }

  getLessonGroups(countryId = null) {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/?country=${countryId}`);
  }

  getLessonGroupsFiltered(country, educationLevel, isDemo, isMiri, isMiriPro) {
    let type = 1;

    if (isMiri) {
      type = 2;
    }
    if (isMiriPro) {
      type = 3;
    }
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/?country=${country}&education-level=${educationLevel}&is_demo=${isDemo}&type=${type}`);
  }

  getLessonGroup(id) {
    return this.api.get(`${config.getEndpointUri()}/content/lesson-groups/${id}/`);
  }

  addLessonGroup(data) {
    return this.api.post(`${config.getEndpointUri()}/content/lesson-groups/`, data);
  }

  deleteLessonGroup(id) {
    return this.api.delete(`${config.getEndpointUri()}/content/lesson-groups/${id}/`);
  }

  editLessonGroup(id, data) {
    return this.api.put(`${config.getEndpointUri()}/content/lesson-groups/${id}/`, data);
  }

  associateLessonGroup(id, data) {
    return this.api.post(`${config.getEndpointUri()}/content/lesson-groups/${id}/associate/`, data);
  }

  getCodes(value, searchKey) {
    let url = `${config.getEndpointUri()}/registry/activation-codes/?status=${value}`;

    if (searchKey !== null && searchKey !== undefined && searchKey.length > 0)
      url += `&key=${searchKey}`;

    return this.api.get(url);
  }

  associateActivationCode(schoolId, codeId) {
    return this.api.post(`${config.getEndpointUri()}/registry/activation-codes/${codeId}/associate/?school_id=${schoolId}`);
  }

  addCodes(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/activation-codes/generatenew/`, data);
  }

  addCode(data) {
    return this.api.post(`${config.getEndpointUri()}/registry/activation-codes/`, data);
  }

  getCode(id) {
    return this.api.get(`${config.getEndpointUri()}/registry/activation-codes/${id}/`);
  }

  editCode(id, data) {
    return this.api.put(`${config.getEndpointUri()}/registry/activation-codes/${id}/`, data);
  }

  resetCode(id) {
    return this.api.post(`${config.getEndpointUri()}/registry/activation-codes/${id}/reset/`);
  }

  acceptConsent(text) {
    return this.api.post(`${config.getEndpointUri()}/core/logs/`, {'content': text});
  }

  getVideo = async (url, onUploadProgressCallback) => {
    let response = await this.api.get(url, {
      onUploadProgress: (progressEvent) => {
        if (onUploadProgressCallback)
          onUploadProgressCallback((progressEvent.loaded / progressEvent.total) * 100);
      },
    });
    return response.data;
  }

  downloadMedia = async (media, onDownloadProgressCallback) => {
    let response = await this.api.get(media.url, 
      { 
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (onDownloadProgressCallback)
            onDownloadProgressCallback(media, (progressEvent.loaded / progressEvent.total) * 100);
        },
      }
    );

    return response.data;
  }

  logout = () => {
    //this.profile = null;
    window.localStorage.clear();
    window.location.replace(window.location.origin + "/login/")
  };
}
