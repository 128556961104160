import { useEffect, useRef, useState } from "react";
import React from 'react'
import Popup from 'reactjs-popup';
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { FaEdit, FaThumbsDown, FaThumbsUp, FaTrash } from 'react-icons/fa';
import { getElementById } from '../../common/functions';
import { Table } from "../../common/table";
import { useTranslation } from "react-i18next";
import Loader from "../../common/loader";

function AdminLessonsPage() {
  const { t } = useTranslation();

  const { auth } = useStores();
  const [confirmId, setConfirmId] = useState<number>(null);
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    searchKey.current = localStorage.getItem('lesson_searchKey');
    
    setIsFilterLoading(true);
    setIsFilterError(false);

    auth.getCountries().then((res) => {
      setCountries(res.data);
      if (res.data.length > 0) {
        let filterCountry = localStorage.getItem('lessons_countryKey');
        if (filterCountry !== undefined && filterCountry !== null) {
          setCurrentCountry(getElementById(res.data, filterCountry));
        } else {
          setCurrentCountry(res.data[0]);
        }
      }
      setIsFilterLoading(false);
    }).catch((error) => {
      setIsFilterLoading(false);
      setIsFilterError(true);
    });
  }, [])

  useEffect(() => {
    getLessons();
  }, [currentCountry])

  const getLessons = () => {
    if (currentCountry == null)
      return;

    setIsDataLoading(true);
    setIsDataError(false);

    auth.getLessons(searchKey.current, currentCountry).then((res) => {
      setLessons(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }
  
  const onDelete = (id) => {
    setConfirmId(id);
  }

  const doDelete = async () => {
    await auth.deleteLesson(confirmId);
    setConfirmId(null);
    getLessons();
  }

  const search = event => {
    if (event.target.value && event.target.value.length < 4 )
      return;

    localStorage.setItem('lesson_searchKey', event.target.value);

    searchKey.current = event.target.value;
    getLessons();
  }

  const handleCountryChange = ({ target }) => {
    localStorage.setItem('lessons_countryKey', target.value);

    let country = getElementById(countries, target.value);
    setCurrentCountry(country);
  }

  const columns = React.useMemo(
      () => [
      {
          Header: t('modules'),
          columns: [
          {
              Header: t('modify'),
              accessor: "id",
              Cell: ({ cell }) => (
                <Link to={`/common/composer/${cell.row.values.id}/`}>
                  <FaEdit />
                </Link>
              )
          },
          {
            Header: t('internalName'),
            accessor: 'name',
          },
          {
              Header: t('title'),
              accessor: 'title',
          },
          {
              Header: t('description'),
              accessor: 'description',
          },
          {
            Header: t('screenRatio'),
            accessor: 'screen_ratio',
          },
          {
            Header: t('completed'),
            accessor: 'is_completed',
            Cell: ({ cell }) => (
              <div>
                { cell.row.values.is_completed && <FaThumbsUp className='text-green'/> }
                { !cell.row.values.is_completed && <FaThumbsDown className='text-red'/> }
              </div>
            )
          },
          {
            Header: t('delete'),
            accessor: "ida",
            Cell: ({ cell }) => (
              <FaTrash onClick={() => onDelete(cell.row.values.id)} className='cursor-pointer' />
            )
          },
          ],
      },
      ],
      []
  );

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><Loader /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
    <div>
      <Popup open={confirmId !== null}
        position="right center"
        closeOnDocumentClick
      >
        <div className="flex flex-col items-center gap-6 p-6">
          <span>{t('confirmDelete')}</span>
          <div className="flex flex-row items-center gap-6">
              <button onClick={() => doDelete()} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('yes')}</button>
              <button onClick={() => setConfirmId(null)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('no')}</button>
          </div>
        </div>
      </Popup>

      <div className="flex flex-col gap-6">
        <div className="flex flex-row justify-start">
          <span className="text-4xl">{t('modules')}</span>
        </div>

        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <select onChange={handleCountryChange} value={currentCountry?.id} className="bg-primary text-white rounded-full p-4 mx-2 cursor-pointer">
              {countries?.map(({ id, name }, index) => <option value={id} >{name}</option>)}
            </select>
            <input onChange={search} ref={searchRef} defaultValue={searchKey.current} placeholder={t('searchMin4Chars')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <Link className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" to="/common/composer/new">{t('add')}</Link>

        </div>
        
        {isDataLoading && <div className='grid h-screen place-items-center'><Loader /></div> }

        {isDataError && <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div> }

        {!isDataError && !isDataLoading && <Table columns={columns} data={lessons} /> }
      </div>
    </div>
  )
}

export default AdminLessonsPage
